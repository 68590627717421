<template>
  <div class="">
    <v-card fluid class="px-5">
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Buscar"
            placeholder="Buscar"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="4" offset="4" class="text-right my-auto">
          <v-btn small color="success" :loading="loadingNuevo" @click="nuevo()"
            >NUEVO</v-btn
          >
          <v-btn
            small
            class="mx-1"
            :loading="loadingFiltrar"
            color="#2962FF"
            @click="filtrar()"
            dark
            >FILTRAR</v-btn
          >
          <v-btn small class="mx-1" color="#004D40" dark>EXPORTAR</v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="$store.state.begend.lstBegend"
            class="elevation-1"
            item-key="port_beginend_id"
            :loading="loading"
            :search="search"
          >
            <template v-slot:[`item.estado`]="{ item }">
              {{ !!item.status == 1 ? "Activo" : "Inactivo" }}
            </template>
            <template v-slot:[`item.colors`]="{ item }">
              <v-chip :color="item.color" v-if="item.color">
                {{ item.color }}
              </v-chip>
            </template>
            <template v-slot:[`item.statuscalls`]="{ item }">
              <v-chip color="green" v-if="item.status_calls == 1" dark
                >Si</v-chip
              >
              <v-chip color="red" v-else dark>No</v-chip>
            </template>
            <template v-slot:[`item.statuscalls_all`]="{ item }">
              <v-chip color="green" v-if="item.status_calls_all == 1" dark
                >Si</v-chip
              >
              <v-chip color="red" v-else dark>No</v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="ver(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Ver</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="warning"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="editar(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.status == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="error"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="eliminar(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      search: "",
      loading: false,
      loadingNuevo: false,
      loadingFiltrar: false,
      headers: [
        { text: "Código", value: "code" },
        { text: "Nombre", value: "name" },
        { text: "Descripción", value: "description" },
        { text: "Posición (Orden)", value: "position" },
        { text: "Color", value: "colors" },
                { text: "Estado", value: "estado" },{ text: "Creación", value: "created_at" },

        { text: "Última Actualización", value: "updated_at" },
        { text: "Acciones", value: "action" },
      ],
    };
  },
  methods: {
    ...mapActions(["GetListBegend", "ActualizarBegend"]),
    async filtrar() {
      this.$store.state.begend.tipo = "filter";

      this.$store.state.begend.drawer = !this.$store.state.begend.drawer;
    },
    async ver(item) {
      let datos = { ...item };
      this.$store.state.begend.tipo = "ver";
      this.$store.state.begend.model = datos;

      this.$store.state.begend.drawer = !this.$store.state.begend.drawer;
    },
    async editar(item) {
      let datos = { ...item };
      this.$store.state.begend.tipo = "editar";
      this.$store.state.begend.model = datos;

      this.$store.state.begend.drawer = !this.$store.state.begend.drawer;
    },
    async nuevo() {
      this.$store.state.begend.tipo = "nuevo";
      this.$store.state.begend.model = {
        id: "",
        position: "",
        ccode: "",
        name: "",
        description: "",
        color: "",
        status: 1,
        id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
      };

      this.$store.state.begend.drawer = !this.$store.state.begend.drawer;
    },
    async eliminar(item) {
      Swal.fire({
        icon: "question",
        title: "¿Estás seguro?",
        text: `Si inactiva el registro, éste no aparecerá disponible en otros formularios ¿Está seguro de que desea inactivarlo?`,
        allowOutsideClick: false,
        confirmButtonText: "Si, inactivar",
        confirmButtonColor: "red",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "orange",
        showCancelButton: true,
      }).then(async (res) => {
        if (res.isConfirmed) {
          this.$store.state.begend.filtros = {
            id: "",
            position: "",
            code: "",
            name: "",
            description: "",
            color: "",
            status: "1",
          };

          item.status = 0;
          this.$store.state.begend.model = item;
          this.loading = true;
          await this.ActualizarBegend();
          await this.GetListBegend();
          this.loading = false;
        }
      });
    },
  },
  async mounted() {
    this.loading = !this.loading;
    await this.GetListBegend();
    this.loading = !this.loading;
  },
};
</script>

<style></style>
